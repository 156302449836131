import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5rem 0rem 2rem 0rem;
  padding-bottom: 4rem;
  @media (max-width: 575.98px) {
    padding-top: 7rem;
  }
`

