import { useEffect, useState } from 'react'
import {
  Button,
  Container,
  ContainerGaleria,
  Tab,
  ContainerSkeleton,
} from './styles'
import { SwipperGaleria } from '../SwiperGaleria'
import { Skeleton } from '@mui/material'
import axios from 'axios'

const localApi = axios.create({
  baseURL: 'https://quadra.com.vc/api/',
})

export function GaleriaInterativa() {
  const [areaComumButton, setAreaComumButton] = useState(false)
  const [apartamentosButton, setApartamentosButton] = useState(true)
  const [plantasButton, setPlantasButton] = useState(false)

  const [slidesData, setSlidesData] = useState([{}])
  const [apartaments, setApartaments] = useState([{}])
  const [areasComuns, setAreasComuns] = useState([{}])
  const [plantas, setPlantas] = useState([{}])
  const [load, setLoad] = useState(false)

  async function axiosFunction() {
    setLoad(true)
    const response = await localApi.get('empreendimentos/2/pictures') // Lux Design Concept
    // const response = await localApi.get("empreendimentos/4/pictures"); // Lux Design Living
    // const response = await localApi.get("empreendimentos/5/pictures"); // Piazza by Quadra
    setApartaments(response.data['Apartamentos'])
    // setFachadas(response.data.fachadas);
    setPlantas(response.data['Plantas'])
    setAreasComuns(response.data['Áreas Comuns'])
    setSlidesData(response.data['Apartamentos'])
    setLoad(false)
  }

  useEffect(() => {
    axiosFunction()
  }, [])

  function handleResetButtons() {
    setAreaComumButton(false)
    setApartamentosButton(false)
    // setFachadasButton(false);
    setPlantasButton(false)
  }

  function handleChangeSwiper(props) {
    handleResetButtons()
    setSlidesData(props)
    if (props === areasComuns) {
      setAreaComumButton(true)
    }
    if (props === apartaments) {
      setApartamentosButton(true)
    }
    // if (props === fachadas) {
    //   setFachadasButton(true);
    // }
    if (props === plantas) {
      setPlantasButton(true)
    }
  }

  return (
    <Container>
      <Tab>
        <Button
          type="button"
          onClick={() => handleChangeSwiper(apartaments)}
          isActive={apartamentosButton}
        >
          Apartamentos
          <span className="line-bottom"></span>
        </Button>
        <Button
          type="button"
          onClick={() => handleChangeSwiper(areasComuns)}
          isActive={areaComumButton}
          className="line-bottom "
        >
          Áreas Comuns
          <span className="line-bottom"></span>
        </Button>
        {/* <Button
          type="button"
          onClick={() => handleChangeSwiper(fachadas)}
          isActive={fachadasButton}
        >
          Fachadas
        </Button> */}
        <Button
          type="button"
          onClick={() => handleChangeSwiper(plantas)}
          isActive={plantasButton}
        >
          Plantas
          <span className="line-bottom"></span>
        </Button>
      </Tab>
      <ContainerGaleria>
        {load ? (
          <ContainerSkeleton>
            <Skeleton variant="rect" className="skeletonImage" />
            <div className="w-100">
              <div className="d-flex justify-content-between mt-3">
                <Skeleton
                  sx={{ bgcolor: 'grey.500' }}
                  variant="rect"
                  width={200}
                  height={30}
                />
                <Skeleton
                  sx={{ bgcolor: 'grey.500' }}
                  variant="rect"
                  width={60}
                  height={30}
                />
              </div>
            </div>
          </ContainerSkeleton>
        ) : (
          <SwipperGaleria slidesData={slidesData} />
        )}
      </ContainerGaleria>
    </Container>
  )
}

