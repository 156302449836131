// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "./styles.scss";


// import Swiper core and required modules
import SwiperCore, { Navigation, EffectFade, Autoplay, Pagination } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";

// install Swiper modules
SwiperCore.use([Navigation, EffectFade, Autoplay]);

export function SwipperGaleria({ slidesData }) {
  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">

        <Swiper navigation={true} className="SwipperGaleria" pagination={{
          type: 'fraction',
          el: '.swipper-pagination'
        }}
          slidesPerView={1}
          effect="fade"
          autoplay={{
            delay: 1000,
          }}
          speed={1500}

          fadeEffect={
            { effect: "fade", crossFade: true }
          }
          modules={[EffectFade, Autoplay, Pagination]}>
          {slidesData.map((slide, index) => (
            <SwiperSlide key={index}>
              <button
                data-fancybox="single"
                data-src={`https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture}`}
                data-caption={slide.name}
              >

                <div style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: `url(https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture})`, width: '100%', height: '100%' }}>
                  <div style={{ margin: "0px 768px 0px 0px", width: "100%", height: "500px" }}></div>
                </div>
                {/* <img
                  src={`https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture}`}
                  alt={slide.name}
                /> */}
              </button>

              {/* <p className="swipper-pagination"></p> */}
              <div className="footer mt-1">
                <h3>{slide.name}</h3>

                {/* <p>Apartamento de 234m², até 2 suítes, 3 vaga na garagem</p> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollAnimation>
    </>
  );
}
