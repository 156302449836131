import gradeSvg from "../../assets/elements/grade.svg";
import { GaleriaInterativa } from "../GaleriaInterativa";
import { HeaderTitle } from "../HeaderTitle";
import { Container } from "./styles";

export function SectionGaleria() {
  return (
    <Container>
      <div className="header">
        <img src={gradeSvg} alt="grade vertical" className="grade-vertical" />

        <HeaderTitle title={"Galeria"} titleAlign={"center"} />
      </div>
      <GaleriaInterativa />
    </Container>
  );
}
