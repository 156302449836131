import { Phone } from './components/Phone'
import { FirstSection } from './sections/firstSection'
import { SecondSection } from './sections/secondSection'
import { ThirdSection } from './sections/thirdSection'

import { FooterContainer } from './styles'

export function Footer() {
  return (
    <FooterContainer>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
    </FooterContainer>
  )
}

