import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";

import { Container, ContainerSwiper } from "./styles";
import SwipperTestimonials from "../SwiperTestimonials";
SwiperCore.use([Navigation]);
export function SectionFachada() {
  return (
    <Container>
      <ContainerSwiper>
        <SwipperTestimonials />
      </ContainerSwiper>
    </Container>
  );
}
