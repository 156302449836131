import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`

interface ButtonProps {
  isActive?: boolean
}

export const Tab = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

export const Button = styled.button<ButtonProps>`
  border: none;
  padding: 0.5rem 1.5rem;
  position: relative;
  background-color: transparent;
  color: var(--shape-color);
  transition: ease 0.5s all;
  .line-bottom {
    height: 1px;
    width: ${(props) => (props.isActive ? '100%' : '0rem')};
    display: flex;
    position: absolute;
    left: 0px;
    bottom: 0px;
    transition: ease 0.5s all;
    background: var(--gold-color);
  }
`
export const ContainerSkeleton = styled.div`
  max-width: 768px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .skeletonImage {
    width: 100%;
    height: 432px;
    background-color: var(--brow-color-5);
  }
`

export const ContainerGaleria = styled.div``

