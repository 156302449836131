import { HeaderTitle } from '../HeaderTitle'
import { MainContainer } from '../MainContainer'
import { Container } from './styles'
import pinIcon from '../../assets/empreendimento/location.svg'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
export function SectionRegiao() {
  return (
    <MainContainer>
      <Container>
        <HeaderTitle title={'Localização'} titleAlign={'center'} />
        <Container>
          <section className="container-localizacao">
            <div className="card-localizacao">
              <img
                src="https://www.radiosromafm.com.br/wp-content/uploads/sites/11/2022/04/WhatsApp-Image-2022-04-30-at-10.41.17-1024x576.jpeg"
                alt=""
              />
              <div>
                <span>.</span>
                <div>
                  <h2>SUPERMERCADO</h2>
                  <p>8 minutos a pé</p>
                </div>
              </div>
            </div>
            <div className="card-localizacao">
              <img src="/tmp/hospital.jpg" alt="" />
              <div>
                <span>.</span>
                <h2>HOSPITAL SÃO JOÃO DE DEUS</h2>
                <p>8 minutos a pé</p>
              </div>
            </div>
            <div className="card-localizacao">
              <img
                src="https://media-cdn.tripadvisor.com/media/photo-s/0b/8c/a9/f8/frente-do-shopping-doca.jpg"
                alt=""
              />
              <div>
                <span>.</span>
                <h2>SHOPPING BOULEVARD</h2>
                <p>10 minutos de carro</p>
              </div>
            </div>
            <div className="card-localizacao">
              <img
                src="https://static.consolidesuamarca.com.br/data/images/umarizal-rn.jpg?18092019"
                alt=""
              />
              <div>
                <span>.</span>
                <h2>PRAÇA BARÃO DO RIO BRANCO</h2>
                <p>8 minutos a pé</p>
              </div>
            </div>
          </section>
          <ScrollAnimation animateIn="animate__fadeInUp">
            <h3>
              {' '}
              <img src={pinIcon} alt="icone de pino" /> Av. Pedro Álvares
              Cabral, 1300 - Umarizal
            </h3>
          </ScrollAnimation>
        </Container>
      </Container>
    </MainContainer>
  )
}

