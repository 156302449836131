import styled from 'styled-components'
import backgroundHeroImg from '../../assets/hero.png'

type MenuProps = {
  isScrollOnTop: boolean
}

export const Menu = styled.nav<MenuProps>`
  position: fixed;
  height: ${({ isScrollOnTop }) => (isScrollOnTop ? '6.5rem' : '5rem')};
  width: 100%;
  left: 0;
  z-index: 999;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  padding: ${({ isScrollOnTop }) => (isScrollOnTop ? '0' : '4px')};

  .grid-container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    height: ${({ isScrollOnTop }) => (isScrollOnTop ? '6.5rem' : '5rem')};
    align-items: center;
  }

  .inside-grid-container {
    grid-column: 2 / 12;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${({ isScrollOnTop }) => (isScrollOnTop ? '7.5rem' : '5.4rem')};
    width: 100vw;
    background-color: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'transparent' : '#fff'};
    transition: 0.2s ease-in-out;
  }

  .logo-container {
    grid-column: 2 / 3;
  }

  .logo-quadra {
    height: 100px;
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop
        ? 'translate(-0.5px ,calc(30px + 0.5rem))'
        : 'translateX(0)'};
    object-fit: contain;
    z-index: 10;
    transition: 0.2s ease-in-out;
  }

  .logo-quadra-blue {
    height: 49.01px;
    width: 59.4px;
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'translateY(calc(30px + 0.5rem))' : 'translateY(0)'};
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'translateX(0)' : 'translateX(44px)'};
    object-fit: contain;
    z-index: 10;
    transition: 0.2s ease-in-out;
  }

  .nav-buttons-container {
    display: flex;
    align-items: center;
    grid-column-end: 12;

    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'translate(-4px, 8px)' : 'translateX(0)'};

    .search-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: #525252;
      border-radius: 50%;
      opacity: 0.9;

      img {
        height: 20px;
        width: 20px;

        filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
          drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
      }
    }

    @media only screen and (max-width: 1215px) {
      display: none;
    }

    /* 
    Small ≥576px	
    Medium ≥768px	
    Large ≥992px	
    Extra large ≥1200px 
  */
  }

  .nav-links-container {
    display: flex;
  }

  .nav-link {
    position: relative;
    font-size: 1.125rem;
    padding: 0 0.75rem;
    transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    text-shadow: ${({ isScrollOnTop }) =>
      isScrollOnTop
        ? '0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1)'
        : 'none'};
    color: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'var(--light-gray-color)' : '#1891f1'};

    &.active::before {
      content: '';
      position: absolute;
      left: ${({ isScrollOnTop }) => (isScrollOnTop ? '16.5px' : '20px')};
      bottom: -4px;
      width: ${({ isScrollOnTop }) =>
        isScrollOnTop ? 'calc(100% - 32px)' : 'calc(100% - 40px)'};
      height: 2px;
      background-color: ${({ isScrollOnTop }) =>
        isScrollOnTop ? '#fff' : '#1891f1'};
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`

export const Hero = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundHeroImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;

  .hero-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 22rem;
      margin-top: -4rem;
    }
  }

  .container-logo {
    margin-top: 20rem;
    padding: 1rem;
    background-size: cover;
    display: inline-block;
    border-radius: 4px;
    img {
      width: 420px;
      filter: drop-shadow(1px 1px 15px black);
    }
  }
  .container-content {
    margin: 8rem auto 0 auto;
    width: 22rem;
    display: grid;
    place-items: center;
    height: 50%;
    z-index: 10;

    &:hover {
      .ping-element {
        background-color: var(--shape-color);
        svg {
          path {
            fill: var(--gold-color);
          }
        }
      }
      .container-content-description {
        opacity: 1;
      }
    }
    div {
      h2 {
        margin: 0;
        padding: 0;
        font-family: 'Gilroy Light';
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .container-content-description {
      opacity: 0;
      width: 18rem;
      color: var(--shape-color);
      text-align: center;
      transition: opacity 0.3s ease;
    }
    .ping-element {
      margin: 2rem auto;
      display: grid;
      place-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      width: 4rem;
      aspect-ratio: 1;
      border-radius: 100%;
      cursor: pointer;
      transition: all 0.3s ease;

      svg {
        width: 1rem;
        path {
          fill: var(--shape-color);
          transition: all 0.3s ease;
        }
      }
    }
  }
`

