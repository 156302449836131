import formBannerImg from '../../assets/form-banner.png'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 60rem;
  position: relative;

  .background {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 53rem;
  }

  .section-formulario-form {
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 36rem;
    .header {
      height: 12rem;
      background: transparent
        linear-gradient(91deg, #cb8f55 0%, #9a6c40 49%, #66482b 100%) 0% 0%
        no-repeat padding-box;
      // background-image: url(${formBannerImg});
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center;
      display: grid;
      place-items: center;
      h3,
      p {
        text-align: center;
        color: var(--shape-color);
        font-family: 'Gilroy Light';
      }
      p {
        filter: brightness(0.8);
      }
    }
    .content-inputs {
      display: flex;
      flex-direction: column;
      background-color: var(--shape-color);
      padding: 3rem;
      @media (max-width: 767.98px) {
        padding: 2rem;
      }

      input,
      label {
        height: 3rem;
        line-height: 3rem;
        width: 100%;
      }

      label {
        color: var(--gold-color);
      }
      input,
      .input-theme {
        border: none;
        border-bottom: 2px solid #cb8f5580;
        padding-left: 1rem;
        &:focus {
          outline: none;
          border: none;
        }
        &[type='submit'] {
          background: transparent
            linear-gradient(91deg, #cb8f55 0%, #9a6c40 49%, #66482b 100%) 0% 0%
            no-repeat padding-box;
          border: none;
          color: var(--shape-color);
          transition: all 0.2s ease;
          margin-top: 5rem;
          padding-left: 0;
          &:hover {
            filter: brightness(0.9);
          }
        }
      }
      &::placeholder {
        color: #c2c2c2;
      }
    }
  }
`

