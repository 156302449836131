import styled from 'styled-components'

type responsiveNavContainerProps = {
  isScrollOnTop: boolean
}

export const ResponsiveNavContainer = styled.button<responsiveNavContainerProps>`
  display: none;
  height: 46px;
  width: 64px;
  background-color: #fff;
  border-radius: 24px;
  outline: none;
  border: none;
  z-index: 10;
  border: 1px solid #e3e3e3;
  transition: 0.2s ease-in-out;

  &:hover {
    border: ${({ isScrollOnTop }) =>
      isScrollOnTop ? 'none' : '2px solid #1891f1'};
  }

  img {
    height: 34px;
    width: 34px;
  }

  @media only screen and (max-width: 1215px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  gap: 1.25rem;
  padding: 2rem 4rem;

  a {
    color: #6f6f6f;
    text-decoration: none;
  }

  .close-button {
    position: absolute;
    left: 4px;
    top: 4px;
  }
`

