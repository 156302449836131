import React, {useState, useEffect} from "react";
import gradeSvg from "../../assets/elements/grade.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, FeatureContent, FooterContent } from "./styles";
import { HeaderTitle } from "../HeaderTitle";
import { PlayVideo } from "../PlayVideo";

import quadraTechImg from "../../assets/quadra-tech.png";
import imgVideo from "../../assets/img-video.webp";
import videoModal from "../../assets/videos/video.mp4";


export function SectionPraticidade() {
  return (
    <Container>
      <img src={gradeSvg} alt="grade vertical" className="grade-vertical" />

      <HeaderTitle
        title="Praticidade"
        description="Os espaços são pensados para transmitir leveza e harmonia. Feitos para quem gosta de viver com classe e não abre mão da qualidade de vida."
        titleAlign="center"
        descriptionAlign="center"
      />
      <div className="row row-cols-md-3 justify-content-center">
        <FeatureContent>
          <div className="content">
            <ScrollAnimation animateIn="animate__fadeInLeft">
              <h3>Studios ou Suítes</h3>
              <h3>39,40 a 78,81m²</h3>
            </ScrollAnimation>
          </div>
        </FeatureContent>
        <FeatureContent isMid={true}>
          <ScrollAnimation animateIn="animate__fadeInDown">
            <h3 className="gold-item">
              1<span>ou</span>2
            </h3>
            <div className="content">
              <h3>Vagas de</h3>
              <h3>Garagem</h3>
            </div>
          </ScrollAnimation>
        </FeatureContent>
        
        <FeatureContent>
          <ScrollAnimation animateIn="animate__fadeInRight">
            <h3 className="gold-item">8</h3>
            <div className="content">
              <h3>Coberturas duplex</h3>
              <h3>78,81m a 153,40m²</h3>
            </div>
          </ScrollAnimation>
        </FeatureContent>
      </div>

      <FooterContent>
        <div>
          <h3>Tecnologia com selo</h3>
          <img src={quadraTechImg} alt="Quadra Tech" />
        </div>
        <ScrollAnimation animateIn="animate__fadeInUp">
          <p>
            Buscamos o que existe de mais moderno para você morar bem. Não é apenas automação. É inteligência para oferecer o máximo de segurança, facilidade e a qualidade de vida que você merece.
          </p>
        </ScrollAnimation>
      </FooterContent>

      <br/>
        <h2 className="text-gold text-thin text-center mt-5"> ASSISTA AO VÍDEO E SAIBA MAIS SOBRE O EMPREENDIMENTO </h2>
      <br/>

      <PlayVideo
        imagemCapa={imgVideo}
        video={videoModal}
        idModal="videoConcept"
        textoAlt="Vídeo Quadra Concept"
      />
      
    </Container>
  );
}
