import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://api-temp.vercel.app/api/quadra',
})

export const api_prod = axios.create({
  baseURL: 'https://quadra.com.vc/api',
}) //test

