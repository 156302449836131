import styled from 'styled-components'

export const Container = styled.div`
  padding: 5rem 1rem 8rem 1rem;
  background-color: transparent;
  .grade-vertical {
    position: absolute;
    left: 0;
    width: 6rem;
    @media (max-width: 767.98px) {
      width: 2rem;
    }
  }
  .header {
    div {
      max-width: 100%;
    }
  }
`

