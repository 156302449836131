import partnersLogoImg from '../../assets/logos/partners-gray.svg'
import partnersLogoImgSegundo from '../../assets/logos/partners-dourado.svg'
import quadraLogoImg from '../../assets/logos/quadra-blue.svg'
import quadraLogoImgSegundo from '../../assets/logos/quadra-dourado.svg'
import luxEmpreendimentos from '../../assets/logos/lux-emprendimentos.svg'
import luxEmpreendimentosSegundo from '../../assets/logos/lux-emprendimentos-dourado.svg'
import { Container, SupItems } from './styles'
import ScrollAnimation from 'react-animate-on-scroll'

export function Companies() {
  return (
    <Container>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <SupItems>
          <div className="imagem-hover">
            <span>Comercialização</span>
            <a href="" className="anchor-footer-image">
              <div className="imagemHover-on text-center">
                <img src={partnersLogoImg} alt="Partners Empreendimentos" />
              </div>

              <div className="imagemHover-off text-center">
                <img
                  src={partnersLogoImgSegundo}
                  alt="Partners Empreendimentos"
                />
              </div>
            </a>
          </div>
          <div className="imagem-hover">
            <span>Construção e administração</span>
            <a href="http://quadra.com.vc/" target="_blank">
              <div className="imagemHover-on text-center">
                <img src={quadraLogoImg} alt="Quadra Engenharia" />
              </div>
              <div className="imagemHover-off text-center">
                <img src={quadraLogoImgSegundo} alt="Quadra Engenharia" />
              </div>
            </a>
          </div>
          <div className="imagem-hover">
            <span>Incorporação</span>
            <a href="" className="anchor-footer-image">
              <div className="imagemHover-on text-center">
                <img src={luxEmpreendimentos} alt="Lux Empreendimentos" />
              </div>
            </a>
            <div className="imagemHover-off text-center">
              <img src={luxEmpreendimentosSegundo} alt="Lux Empreendimentos" />
            </div>
          </div>
        </SupItems>
      </ScrollAnimation>
      {/* <div className="text-center">
        <img src={luxEmpreendimentos} alt="Concept" className="footer-logo" />
      </div> */}
      <p className="copyright">
        Todas as imagens e plantas são meramente ilustrativas com sugestão de
        decoração. Os móveis, assim como alguns materiais de acabamento
        representados nas plantas, não fazem parte do contrato de vendas.
        Projeto em aprovação e sujeito a alterações de acordo com o memorial
        descritivo de empreendimento.
      </p>
    </Container>
  )
}

