import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid gray;
  max-width: 17rem;
  min-height: 180px;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  &:hover {
    transform: translateY(-10px);
    border: 1px solid white;
    transition: 0.5s ease all;
  }
  @media (max-width: 1200.98px) {
    justify-self: center;
  }
  .imagem-carrossel {
    display: flex;
    
    img {
      margin-right: 0rem;
      height: 50px;
      object-fit: cover;
      width: 50px;
    }
  }

  div {
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
      color: var(--shape-color);
    }
  }
`

