import areaExterna from '../../assets/comodidades/area-externa-de-cross.svg'
import barbecue from '../../assets/comodidades/barbecue.svg'
import coworking from '../../assets/comodidades/coworking.svg'
import deckChair from '../../assets/comodidades/deck-chair.svg'
import energy from '../../assets/comodidades/energy.svg'
import fingerprint from '../../assets/comodidades/fingerprint.svg'
import gym from '../../assets/comodidades/gym.svg'
import Laundry from '../../assets/comodidades/laundry.svg'
import loungPub from '../../assets/comodidades/lounge-pub.svg'
import poolLouge from '../../assets/comodidades/lounge.png'
import mountainBike from '../../assets/comodidades/mountain-bike.svg'
import petCare from '../../assets/comodidades/pet-care.svg'
import petPlace from '../../assets/comodidades/pet-place.svg'
import poolHouse from '../../assets/comodidades/pool-house.svg'
import salaSpinnig from '../../assets/comodidades/sala-de-spinnig.svg'
import salaJogos from '../../assets/comodidades/sala-jogos.svg'
import sauna from '../../assets/comodidades/sauna.svg'
import securityGuard from '../../assets/comodidades/security-guard.svg'
import sistemaAguaChuva from '../../assets/comodidades/sistema-de-agua-da-chuva.svg'
import sistemaEnergia from '../../assets/comodidades/sistema-de-energia-solar.svg'
import swimmingPool from '../../assets/comodidades/swimming-pool.svg'
import usb from '../../assets/comodidades/usb.svg'
import wifi from '../../assets/comodidades/wifi.svg'
import wineLoung from '../../assets/comodidades/wine-lounge.svg'
export interface IComodidades {
  id: number
  comodidadeImg: string
  textSup: string
  textSub: string
}

export const comodidades: IComodidades[] = [
  {
    id: 1,
    comodidadeImg: swimmingPool,
    textSup: 'Piscina ',
    textSub: 'com raia semiolímpica',
  },
  {
    id: 2,
    comodidadeImg: barbecue,
    textSup: 'Churrasqueira',
    textSub: 'Gourmet',
  },
  {
    id: Math.random(),
    comodidadeImg: mountainBike,
    textSup: 'Bicicletário',
    textSub: '',
  },
  {
    id: 3,
    comodidadeImg: deckChair,
    textSup: 'Deck Molhado',
    textSub: '',
  },
  {
    id: 5,
    comodidadeImg: fingerprint,
    textSup: 'Fechadura Digital',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: securityGuard,
    textSup: 'Guarita',
    textSub: 'Blindada',
  },
  {
    id: 6,
    comodidadeImg: gym,
    textSup: 'Fitness',
    textSub: '',
  },
  {
    id: 7,
    comodidadeImg: sauna,
    textSup: 'Sauna',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: usb,
    textSup: 'Tomadas USB',
    textSub: '',
  },
  /* {
    id: Math.random(),
    comodidadeImg: wheelchair,
    textSup: 'Elevador PCD',
    textSub: '',
  }, */
  {
    id: 4,
    comodidadeImg: energy,
    textSup: 'Gerador Total',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: areaExterna,
    textSup: 'Área externa para cross-trainig',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: salaSpinnig,
    textSup: 'Sala de Spinning',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: sistemaAguaChuva,
    textSup: 'Sistema de Reutilização',
    textSub: 'para Água da Chuva',
  },
  // {
  //   id: Math.random(),
  //   comodidadeImg: circuitoSeguranca,
  //   textSup: "Circuito de Segurança",
  //   textSub: "com Alta Tecnologia",
  // },
  {
    id: Math.random(),
    comodidadeImg: wifi,
    textSup: 'Wi-Fi nas áreas sociais',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: sistemaEnergia,
    textSup: 'Sistema de Captação',
    textSub: 'de Energia Solar',
  },
  {
    id: Math.random(),
    comodidadeImg: poolHouse,
    textSup: 'Pool House',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: poolLouge,
    textSup: 'Pool Lounge',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: Laundry,
    textSup: 'Laundry',
    textSub: '',
  },
  // {
  //   id: Math.random(),
  //   comodidadeImg: espacoColiving,
  //   textSup: "Espaço Coliving",
  //   textSub: "",
  // },
  {
    id: Math.random(),
    comodidadeImg: wineLoung,
    textSup: 'Wine Lounge',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: petPlace,
    textSup: 'Pet Place',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: petCare,
    textSup: 'Pet Care',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: loungPub,
    textSup: 'Lounge Pub',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: salaJogos,
    textSup: 'Sala de Jogos Adulto',
    textSub: '',
  },
  {
    id: Math.random(),
    comodidadeImg: coworking,
    textSup: 'Coworking',
    textSub: '',
  },
]

