import poolHouseImg from '../../assets/pool-house.png'
import { Container, ColumnImage } from './styles'

export function SectionPiscina() {
  return (
    <>
      <Container>{/* <img src={poolHouseImg} alt="Pool House" /> */}</Container>
      <ColumnImage>
        <div style={{ backgroundImage: 'url(/tmp/bicicletario.png' }}>
          <h3>BICICLETÁRIO</h3>
        </div>
        <div style={{ backgroundImage: 'url(/tmp/espacofit.png' }}>
          <h3> ESPAÇO FIT</h3>
        </div>
        <div style={{ backgroundImage: 'url(/tmp/academia.png' }}>
          {' '}
          <h3>ACADEMIA</h3>
        </div>
        <div style={{ backgroundImage: 'url(/tmp/jogosadultos.png' }}>
          <h3> JOGOS ADULTOS</h3>
        </div>
      </ColumnImage>
    </>
  )
}

