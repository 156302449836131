import styled from 'styled-components'

export const SecondSectionContainer = styled.div`
  width: 100%;
  min-height: 14.75rem;
  outline: 1px solid #333;
  background-color: #2b2d33;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .second-section-content-container {
    width: 80%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    gap: 8rem;

    @media (max-width: 575.98px) {
      justify-content: center;
      text-align: center;
      width: 100%;
    }

    .contact-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .contact-info-container {
        display: flex;
        flex-direction: column;
      }
    }

    h1 {
      font-size: 1.313rem;
      font-weight: 700;
      color: rgb(168, 172, 182);
      margin: 0;
      font-family: 'Gilroy ExtraBold', sans-serif;
      text-transform: uppercase;
    }

    span {
      color: rgb(255, 255, 255);
      font-size: 1.125rem;
      font-weight: 400;
      font-family: 'Gilroy Light', Verdana, sans-serif;
    }

    .social-media-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .social-media-icons-container {
      display: flex;
      gap: 0.5rem;
    }

    .social-media-icon-container {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 1px #fff;
      padding: 0.5rem;
      border-radius: 4px;

      .icon {
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 0;
        background-color: #1891f1;
        border-radius: 4px;
        transition: 0.2s ease-in-out;
      }

      &:hover {
        &::after {
          height: 100%;
        }
      }
    }
  }

  .phone-container {
    position: absolute;
    right: 20rem;
    bottom: 10rem;

    @media only screen and (max-width: 1450px) {
      right: 10rem;
    }

    @media only screen and (max-width: 1215px) {
      display: none;
    }

    @media only screen and (min-width: 1600px) {
      position: relative;
      left: 10rem;
      bottom: 5rem;
    }
  }
  @media only screen and (max-width: 990px) {
    height: fit-content;
    padding: 2rem;
  }
`

