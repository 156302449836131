import styled from 'styled-components'

export const ThirdSectionContainer = styled.div`
  width: 100%;
  min-height: 23.938rem;
  background-color: #2b2d33;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .footer-text-container {
      font-size: 1.125rem;

      span {
        color: rgb(168, 172, 182);
      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .third-section-content-container {
    margin-top: 25px;
    width: 80%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;

    @media (max-width: 575.98px) {
      justify-content: center;
      text-align: center;
      width: 100%;
    }

    .footer-links-container {
      display: flex;
      flex-wrap: wrap;
    }

    .columns-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7rem;

      @media (max-width: 575.98px) {
        justify-content: center;
        text-align: center;
        width: 100%;
      }
    }

    .column-container {
      display: flex;
      flex-direction: column;

      h4 {
        color: rgb(168, 172, 182);
        font-family: 'Gilroy Light', Verdana, sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 2px !important;
      }
      p {
        text-decoration: none;
        color: #fff;
        font-size: 0.9rem;
        margin-bottom: 4px !important;
        &:hover {
          opacity: 0.8;
        }
      }
      a {
        text-decoration: none;
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }

      .links-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
      }
    }
  }

  @media only screen and (max-width: 990px) {
    height: fit-content;
    padding: 2rem;
  }
`

