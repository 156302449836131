// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/grid'
import './styles.scss'
// import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay, Grid } from 'swiper'
import { Comodidade } from '../Comodidade'

import ScrollAnimation from 'react-animate-on-scroll'
import { comodidades } from './comodidadesData'
import Delayed from '../helpers/Delayed'
// install Swiper modules
SwiperCore.use([Navigation])

export function SwiperComodidade() {
  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">
        <Swiper
          navigation={true}
          slidesPerView={4}
          spaceBetween={8}
          className="swipper-comodidade"
          autoplay={true}
          modules={[Navigation, Grid, Autoplay]}
          grid={{
            rows: 2,
            fill: 'row',
          }}
          breakpoints={{
            200: {
              slidesPerView: 2,
            },
            630: {
              slidesPerView: 2,
            },

            767: {
              slidesPerView: 3,
            },

            1023: {
              slidesPerView: 4,
            },
          }}
        >
          {comodidades.map((comodidade) => (
            <SwiperSlide>
              <Comodidade
                key={comodidade.id}
                comodidadeImg={comodidade.comodidadeImg}
                textSup={comodidade.textSup}
                textSub={comodidade.textSub}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollAnimation>
    </>
  )
}

