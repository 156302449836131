import styled from "styled-components";

interface PlayerVideo {
  titleAlign?: "start" | "center";
  descriptionAlign?: "start" | "center";
}

export const Container = styled.div<PlayerVideo>`

`;
