import styled from 'styled-components'
import piscinaImg from '../../assets/piscina.png'

export const Container = styled.section`
  background-image: url(${piscinaImg});
  background-color: transparent;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 70vh;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 0;
    width: calc(100% - 8rem);
    max-width: 30rem;
    min-width: 20rem;
    height: auto;
    z-index: 1;
    @media (max-width: 575.98px) {
      top: 24rem;
    }
  }
`

export const ColumnImage = styled.section`
  background-color: var(--black-color);
  padding-top: 5px;
  gap: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 300px;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(2, 1fr);
    height: 600px;
  }

  @media (max-width: 767.98px) {
    grid-template-columns: repeat(1, 1fr);
    height: 1100px;
  }
  div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    &:hover {
    }
    h3 {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      position: absolute;
      bottom: 10px;
      left: 20px;
      font-size: 1.1rem;
    }
  }
`

