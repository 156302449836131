import { HeaderTitle } from '../HeaderTitle'
import { MainContainer } from '../MainContainer'
import { Container } from './styles'
import Delayed from '../helpers/Delayed'
import { SwiperComodidade } from '../SwiperComodidade'
import { SwiperEmpreedimentos } from '../SwiperEmpreedimentos'
import { empreendimentosData } from '../SwiperEmpreedimentos/empreendimentosData'
export function SectionEmpreedimentos() {
  return (
    <Container>
      <HeaderTitle
        title={'Empreendimentos'}
        titleAlign={'center'}
        href="https://quadra.com.vc/empreendimentos"
      />
      <Container>
        <SwiperEmpreedimentos slidesData={empreendimentosData} />
      </Container>
    </Container>
  )
}

