import ScrollAnimation from "react-animate-on-scroll";
import GradientBorder from "../GradienentBorder";
import { Container } from "./styles";

interface DescriptionBlockProps {
  text?: string;
  strong?: string;
}

export function DescriptionBlock({ strong, text }: DescriptionBlockProps) {
  return (
    <ScrollAnimation animateIn="animate__fadeInLeft">
      <Container>
        <p>
          <strong>{strong}</strong>
        </p>
        <p>{text}</p>
      </Container>
    </ScrollAnimation>
  );
}
