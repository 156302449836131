import { Container } from './styles'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'
interface HeaderTitleProps {
  title: string
  description?: string
  descriptionAlign?: 'start' | 'center'
  titleAlign?: 'start' | 'center'
  href?: string
}

export function HeaderTitle({
  title,
  description,
  descriptionAlign,
  titleAlign,
  href,
}: HeaderTitleProps) {
  return (
    <ScrollAnimation animateIn="animate__fadeInDown">
      <Container descriptionAlign={descriptionAlign} titleAlign={titleAlign}>
        {href ? (
          <a href={href} target="_blank">
            <h2>{title}</h2>
          </a>
        ) : (
          <h2>{title}</h2>
        )}
        <p>{description}</p>
      </Container>
    </ScrollAnimation>
  )
}

