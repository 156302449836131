import exampleSignatureImg from '../../assets/testimonials/signature.png'
import primeiroTestimonial from '../../assets/testimonials/example-1.png'
import segundoTestimonial from '../../assets/testimonials/circle-fernanda.png'
import terceiroTestimonial from '../../assets/testimonials/circle-benedito.png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

import './styles.scss'

// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper'

// install Swiper modules
SwiperCore.use([Navigation])

export default function SwipperTestimonials() {
  return (
    <>
      <Swiper
        navigation={true}
        spaceBetween={16}
        loop={true}
        className="SwipperTestimonials"
      >
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={primeiroTestimonial} alt="" className="img-photo" />
            <div>
              <h3>Couceiro &amp; Rubim</h3>
              <p>
                <span>Projeto arquitetônico</span>
              </p>
              <p>
                "Para fazer um projeto verdadeiramente contemporâneo fomos
                buscar o que existe de mais moderno em todo mundo no conceito de
                morar bem."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="SwiperContent">
            <img src={segundoTestimonial} alt="" className="img-photo" />
            <div>
              <h3>Fernanda Marques</h3>
              <p>
                <span>Projeto de interiores das áreas comuns</span>
              </p>
              <p>
                "Minha principal premissa foi dotar o edifício de interiores de
                uma fina sintonia com a arquitetura existente. Reverenciar em
                todos os momentos a natureza. Enfatizar as transparências, a
                continuidade entre os espaços e dotar os ambientes de um
                equilíbrio delicado."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="SwiperContent">
            <img src={terceiroTestimonial} alt="" className="img-photo" />
            <div>
              <h3>Benedito Abbud</h3>
              <p>
                <span>Projeto de paisagismo</span>
              </p>
              <p>
                "Aqui o paisagismo selecionou espécies que comporão infinitos
                tons de verde na paisagem, trazendo a forte presença da natureza
                que abraça todos os espaços e equipamentos de lazer, com locais
                aconchegantes, cuidadosamente desenhado para todos os
                componentes da família."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}
