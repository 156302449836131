import { Comodidade } from '../Comodidade'
import { HeaderTitle } from '../HeaderTitle'
import { MainContainer } from '../MainContainer'
import { ComodidadeContainer, Container } from './styles'
import Delayed from '../helpers/Delayed'
import { SwiperComodidade } from '../SwiperComodidade'

export function SectionComodidades() {
  return (
    <MainContainer>
      <Container>
        <HeaderTitle
          title="Comodidades"
          description="Para dentro de cada espaço do Lux Design Concept, levamos o máximo de modernidade e conforto. Feitos para quem gosta de viver com classe e não abre mão da qualidade de vida."
          descriptionAlign="start"
          titleAlign="start"
        />
        <ComodidadeContainer>
          <SwiperComodidade />
        </ComodidadeContainer>
      </Container>
    </MainContainer>
  )
}

