import useSWR from 'swr';
import { api } from './api';

export function useFetchByContrato<T>(url: string) {
  const { data, error, isLoading } = useSWR<T>(url, async url => {
    const response = await api.get(
      `https://api-temp.vercel.app/api/quadra${url}`
    );
    return response.data;
  });

  return { data, error, isLoading };
}