import ScrollAnimation from "react-animate-on-scroll";
import gradeSvg from "../../assets/elements/grade.svg";
import lobbyImg from "../../assets/torre-conccept.webp";
import localizationImg from "../../assets/localization.png";
import { DescriptionBlock } from "../DescriptionBlock";
import { Container, Content } from "./styles";

export function SectionTorreDestaque() {
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center order-lg-0 order-md-0 order-1">
            <img src={lobbyImg} alt="Torre Concept" className="img-fluid" />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-1 order-md-1 order-0">
            <Content>
              <div className="description-content">
                <ScrollAnimation animateIn="animate__fadeIn">
                  <h2>Admire o novo. <b>Porque é preciso viver com arte</b></h2>
                </ScrollAnimation>
                <p>
                  E se você pudesse ter mais tempo para
                  você? Para aproveitar a cidade. Para
                  apreciar a beleza dos detalhes. Para
                  desfrutar do conforto de estar em casa.
                  <br />
                  <br />
                  Essa é a vida que você merece e pode
                  ter no novo empreendimento da Quadra:
                  Lux Design Concept. Uma verdadeira
                  obra de arte feita pra você.
                  <br />
                  <br />
                  Todo estilo e conforto unidos às mais
                  modernas tecnologias para facilitar o
                  seu dia a dia. Para você apreciar a vida
                  com olhos de admiração. E do seu jeito.
                </p>
              </div>
          </Content>
          </div>
        </div>
      </div>



      {/* <Content>
        <div className="description-content">
          <ScrollAnimation animateIn="animate__fadeIn">
            <h2>Localização Privilegiada</h2>
          </ScrollAnimation>
          <p>
            O Lux Design Concept está na localização mais estratégica da cidade:
            em pleno Umarizal. Ponto de partida para tudo que Belém oferece de
            melhor. Viva a experiência de estar cercado pelos melhores
            estabelecimentos e serviços.
          </p>
        </div>
      </Content> */}
      {/* <img src={lobbyImg} alt="lobby" className="lobby" />
      <img src={gradeSvg} alt="grade vertical" className="grade-vertical" /> */}

    </Container>
  );
}
