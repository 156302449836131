import styled from 'styled-components'

export const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1220px;
  margin: 5rem auto 0;
  margin-bottom: 2rem;

  .copyright {
    line-height: 1.2;
    font-size: 0.8rem;
    color: var(--shape-color);
    filter: brightness(0.8);
    text-align: center;
  }

  .footer-logo {
    margin-block: 5rem;
  }
`

export const SupItems = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 50px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 767.98px) {
    justify-content: center;
  }

  .anchor-footer-image {
    cursor: pointer;
  }

  .imagem-hover .imagemHover-off {
    display: none;
  }
  .imagem-hover:hover .imagemHover-on {
    display: none;
  }
  .imagem-hover:hover .imagemHover-off {
    display: block;
  }

  div {
    padding: 0.5rem;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 0.8rem;
      text-align: center;
      color: var(--gold-color);
      filter: brightness(0.8);
      font-weight: 400;
      letter-spacing: 1.2px;
      margin-bottom: 2rem;
    }
    img {
      max-width: 100%;
      height: auto;
      @media (max-width: 575.98px) {
        max-width: 5rem;
        margin-top: auto;
      }
    }
  }
`

