import styled from 'styled-components'

export const Container = styled.section`
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (max-width: 575.98px) {
    padding-top: 7rem;
  }
  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .container-localizacao {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      gap: 60px;
    }

    gap: 20px;

    .card-localizacao {
      transition: transform ease 0.5s;
      &:hover {
        transform: translateY(-20px);
        transition: transform ease 0.5s;
      }
    }
    div {
      position: relative;

      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
      div {
        bottom: 0px;
        position: absolute;
        z-index: 9;
        width: 100%;
        h2 {
          width: 100%;
          z-index: 999;
          position: absolute;
          bottom: 0px;
          left: 10px;
          font-size: 0.8rem;
        }
        p {
          width: 100%;
          z-index: 999;
          position: absolute;
          bottom: -15px;
          left: 10px;
          font-size: 0.8rem;
          color: var(--gold-color);
        }
        span {
          height: 110px;
          position: absolute;
          bottom: 0px;
          display: flex;

          width: 100%;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            var(--black-color) 100%
          );
        }
      }
    }
  }
`

