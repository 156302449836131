import LocationIcon from '../../assets/empreendimento/location.svg'
import AreaIcon from '../../assets/empreendimento/area.svg'
import BedIcon from '../../assets/empreendimento/bed.svg'

export const empreendimentosData: object[] = [
  {
    id: 1,
    titulo: 'Lux Design Concept',
    href: '#',
    imagem: {
      url: 'http://quadra.com.vc/img-render/venture-banners/p/capa_cbe74956c5fc490ebd3ae9fb7505f37f.png',
      alt: 'empreendimento concept',
    },
    localizacao: {
      text: 'Umarizal',
      icon: LocationIcon,
    },
    suites: {
      text: '2 Suites',
      icon: BedIcon,
    },
    tamanho: {
      text: ' 39 a 78m²',
      icon: AreaIcon,
    },
  },
  {
    id: 2,
    titulo: 'Piazza by Quadra',
    href: 'https://piazza-by-quadra.quadra.com.vc/',
    imagem: {
      url: 'http://quadra.com.vc/img-render/venture-banners/p/capa_3e11aeb33bb53b1bd2a701e3f81a1f2c.jpg',
      alt: 'empreendimento Piazza by quadra',
    },
    localizacao: {
      text: 'Batista Compos',
      icon: LocationIcon,
    },
    suites: {
      text: '3 Suites',
      icon: BedIcon,
    },
    tamanho: {
      text: ' 234 m²',
      icon: AreaIcon,
    },
  },
  {
    id: 3,
    titulo: 'Art',
    href: 'https://quadra-art.vercel.app/',
    imagem: {
      url: 'http://quadra.com.vc/img-render/venture-banners/p/capa_92c56be13f569310851bb9603525a9e7.png',
      alt: 'empreendimento Art',
    },
    localizacao: {
      text: 'Nazaré',
      icon: LocationIcon,
    },
    suites: {
      text: '3 Suites',
      icon: BedIcon,
    },
    tamanho: {
      text: '68 a 76m²',
      icon: AreaIcon,
    },
  },
  {
    id: 4,
    titulo: 'Lux Design Living',
    href: 'https://lux-design-living.quadra.com.vc/',
    imagem: {
      url: 'http://quadra.com.vc/img-render/venture-banners/p/capa_0e1046bdfdb63a22289db72cd59873f9.png',
      alt: 'empreendimento Living',
    },
    localizacao: {
      text: 'Umarizal',
      icon: LocationIcon,
    },
    suites: {
      text: '3 Suites',
      icon: BedIcon,
    },

    tamanho: {
      text: ' 148 a 170m²',
      icon: AreaIcon,
    },
  },
]

