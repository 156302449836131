import { Icon } from '@iconify/react'
import { SecondSectionContainer } from './styles'
/* 
import aCadaQuadraLogo from '../../../../assets/logos/a-cada-quadra.svg' */
import { Phone } from '../../components/Phone'
import { useFetchByContrato } from '../../../../services/useFetchByContrato'

/* 
   phoneMock: {
      publicacoes: '937',
      seguidores: '18.9K',
      seguindo: '6,8K',
      imagens: [
        'https://api-temp.vercel.app/quadra/mock-cellphone/1.png',
        'https://api-temp.vercel.app/quadra/mock-cellphone/2.png',
        'https://api-temp.vercel.app/quadra/mock-cellphone/3.png',
        'https://api-temp.vercel.app/quadra/mock-cellphone/4.png',
        'https://api-temp.vercel.app/quadra/mock-cellphone/5.png',
        'https://api-temp.vercel.app/quadra/mock-cellphone/6.png',
      ],
    },
  */

type FooterLinks = {
  navLinks: NavLink[]
  phoneMock: {
    publicacoes: string
    seguidores: string
    seguindo: string
    imagens: string[]
  }
}

type NavLink = {
  Empreendimentos?: link[]
  Links?: link[]
}

type link = {
  title: string
  slug: string
}

export function SecondSection() {
  const { data } = useFetchByContrato<FooterLinks>('/footer')
  const cellPhoneMockData = data?.phoneMock
  console.log(data)
  return (
    <SecondSectionContainer>
      <div className="second-section-content-container">
        <div className="contact-container">
          <h1>FALE CONOSCO</h1>
          <div className="contact-info-container">
            <span>(91) 3184.1100</span>
            <span>contato@quadra.com.vc</span>
          </div>
        </div>
        <div className="social-media-container">
          <h1>REDES SOCIAIS</h1>
          <div className="social-media-icons-container">
            <a className="social-media-icon-container" href="#">
              <Icon
                icon="ic:baseline-whatsapp"
                color="white"
                height="24"
                className="icon"
              />
            </a>
            <a className="social-media-icon-container" href="#">
              <Icon
                icon="uil:facebook-f"
                color="white"
                height={24}
                className="icon"
              />
            </a>
            <a className="social-media-icon-container" href="#">
              <Icon
                icon="mdi:instagram"
                color="white"
                height={24}
                className="icon"
              />
            </a>
            <a className="social-media-icon-container" href="#">
              <Icon
                icon="ri:youtube-fill"
                color="white"
                height="24"
                className="icon"
              />
            </a>
          </div>
        </div>

        <div style={{ width: '188px' }}>
          {/*   <img src={aCadaQuadraLogo} style={{ height: '120px' }} /> */}
        </div>
        <div className="phone-container">
          <Phone
            publications={cellPhoneMockData?.publicacoes || ''}
            followers={cellPhoneMockData?.seguidores || ''}
            following={cellPhoneMockData?.seguindo || ''}
            images={cellPhoneMockData?.imagens || []}
          />
        </div>
      </div>
    </SecondSectionContainer>
  )
}

