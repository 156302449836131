// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import './styles.scss'

// import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper'
import ScrollAnimation from 'react-animate-on-scroll'
// install Swiper modules
SwiperCore.use([Navigation, Autoplay])

export function SwiperEmpreedimentos({ slidesData }: any) {
  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">
        <Swiper
          navigation={true}
          className="swipper-empreendimentos"
          modules={[Autoplay, Pagination, Navigation]}
          slidesPerView={3}
          spaceBetween={30}
          autoplay={{
            delay: 1000,
          }}
          speed={1500}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            630: {
              slidesPerView: 2,
              spaceBetween: 30,
            },

            767: {
              slidesPerView: 3,
              spaceBetween: 30,
            },

            3023: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {slidesData.map((slide: any) => (
            <SwiperSlide key={slide.id}>
              <div>
                <a href={slide.href} target="_blank" className="anchor-swiper">
                  <img
                    src={slide.imagem.url}
                    alt={slide.imagem.alt}
                    className="img-swiper-empreedimento "
                  />
                  <div className="back-swiper-image"></div>
                  <div className="footer-card-info ">
                    <h3>{slide.titulo}</h3>
                    <div className="footer-info">
                      <div>
                        <img
                          src={slide.localizacao.icon}
                          alt="icone de localização"
                          className="icon-empreendimento"
                        />
                        <p>{slide.localizacao.text}</p>
                      </div>
                      <div>
                        <img
                          src={slide.suites.icon}
                          alt="icone de localização"
                          className="icon-empreendimento"
                        />
                        <p>{slide.suites.text}</p>
                      </div>
                      <div>
                        <img
                          src={slide.tamanho.icon}
                          alt="icone de localização"
                          className="icon-empreendimento"
                        />
                        <p>{slide.tamanho.text}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollAnimation>
    </>
  )
}

