import { SectionTorreDestaque } from '../SectionTorreDestaque'
import { SectionComodidades } from '../SectionComodidades'
import { SectionCoworking } from '../SectionCoworking'
import { SectionFachada } from '../SectionFachada'
import { SectionLocalizacaoPrivilegiada } from '../SectionLocalizacaoPriveligiada'
import { SectionPraticidade } from '../SectionPraticidade'
import { SectionPiscina } from './../SectionPiscina/index'
import { SectionGaleria } from './../SectionGaleria/index'
import { SectionFormulario } from '../SectionFormulario'
import { SectionEmpreedimentos } from '../SectionEmpreendimento'
import { SectionRegiao } from '../SectionRegiao'

export function Main() {
  return (
    <main>
      <SectionTorreDestaque />
      <SectionLocalizacaoPrivilegiada />
      <SectionPiscina />
      <SectionPraticidade />
      <SectionCoworking />
      <SectionComodidades />
      <SectionFachada />
      <SectionRegiao />
      <SectionGaleria />

      <SectionEmpreedimentos />
      <SectionFormulario />
    </main>
  )
}

